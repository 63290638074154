import React from "react";
import { MarqueeLogoWrapper, Container, Track } from "./style";
import logo from "../../images/graffiti-logo-bw.png"

export const MarqueeLogo = (props) => {
  return (
    <MarqueeLogoWrapper sectionTitle={props.sectionTitle}>
      <Container>
        <Track>
          <div className="content">
            <img src={logo}/>
          </div>
        </Track>
      </Container>
    </MarqueeLogoWrapper>
  );
};
