import React, {useEffect, useState, useRef} from "react";
import { BackToAnimation, Wrapper } from "./style";
import { Intro3d } from "../Intro3d"
import { FloatingMenu } from "../Menu/FloatingMenu";
import isologo from "../../images/isotipo.svg";
import sanityClient from "../../client.js";
import { Footer } from "../Footer";
import { Container,StarContainer, IntroParagraph, VideoWrapper, VideoSection, FadeContainerBlack, FadeContainerBeige } from "./style";
import "bootstrap/dist/css/bootstrap.min.css";
import { FeaturedEvents } from "../FeaturedEvents";
import useElementInertialParallax from "../../lib/inertialParallax";
import { Star } from "../Star";

import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";
import { BEIGE } from "../../constants/colors";

 
export const GlobalScroll = () => {
  const [homeData, setHomeData] = useState(null);
  const [scrollUnlocked, setScrollUnlocked] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);


  const parallaxElement = useRef(null)
  const [starParallaxOffset, setStarParallaxOffset] = useState(0)
  const [imgAParallaxOffset, setImgAParallaxOffset] = useState(0)
  const [imgBParallaxOffset, setImgBParallaxOffset] = useState(0)

  useElementInertialParallax((parallaxValue) => {
    setStarParallaxOffset(25 - parallaxValue * 50)
    setImgAParallaxOffset(-15 + parallaxValue * 30)
    setImgBParallaxOffset(-20 + parallaxValue * 40)
  }, parallaxElement)

  const unlockScroll = () => {
    setScrollUnlocked(true);
    sessionStorage.setItem("unlocked", JSON.stringify(true))
  }

  useEffect(() => {
    const sessionScroll = JSON.parse(sessionStorage.getItem("home-scroll"));
    if (sessionScroll) window.scrollTo(0, sessionScroll)
  }, [scrollUnlocked])

  useEffect(() => {
    const isUnlocked = JSON.parse(sessionStorage.getItem("unlocked"));
    setScrollUnlocked(isUnlocked);

    sanityClient
      .fetch(`
        *[_type == "homeContents"]{
          statement,
          statement_image,
          event1_title,
          event1_city,
          event1_url,
          event1_image1{
            asset ->{
              _id,
              url,
              alt
            }
          },
          event1_image2{
            asset ->{
              _id,
              url,
              alt
            }
          },
          event2_title,
          event2_city,
          event2_url,
          event2_image1{
            asset ->{
              _id,
              url,
              alt
            }
          },
          event2_image2{
            asset ->{
              _id,
              url,
              alt
            }
          },
          event3_title,
          event3_city,
          event3_url,
          event3_image1{
            asset ->{
              _id,
              url,
              alt
            }
          },
          event3_image2{
            asset ->{
              _id,
              url,
              alt
            }
          }
        }
        `
      )
      .then((data) => {
        setHomeData(data[0]);
      })
      .catch(console.error);
  }, []);

  const startAnimationAgain = function () {
    setTimeout(() => {setStartAnimation(true)}, 700)
    setTimeout(() => {setStartAnimation(false)}, 1200)
  }

  return (
      <Wrapper>
        <Intro3d onEnterClick={unlockScroll} restart={startAnimation} intro={(!scrollUnlocked)}/>
        
        <Container ref={parallaxElement} show={scrollUnlocked}>
          <StarContainer>
            <Star size={50} top={30} left="5"  parallax={starParallaxOffset} />
            <Star size={70} top={55} left="55" parallax={starParallaxOffset} />
            <Star size={55} top={115} left="25" parallax={starParallaxOffset} />
            <Star size={65} top={150} left="75" parallax={starParallaxOffset} />
            <Star size={50} top={200} left="45" parallax={starParallaxOffset} />
            <Star size={60} top={255} left="10" parallax={starParallaxOffset} />
            <Star size={70} top={285} left="75" parallax={starParallaxOffset} />
          </StarContainer>
          <VideoSection>
            <FadeContainerBlack >
              <FadeContainerBeige >
                {homeData && homeData.statement_image && 
                  <img src={imageUrlFor(buildImageObj(homeData.statement_image)).url()} alt="people dancing"></img>
                }
                <VideoWrapper>
                  <iframe src="https://player.vimeo.com/video/772789542?h=29404c4ed3&amp;autoplay=1&amp;loop=1&amp;background=1&amp;muted=1" 
                width="640" height="360" frameBorder="0" scrolling="no" allow="autoplay" title="take flight"></iframe>
                </VideoWrapper>
               

              </FadeContainerBeige>
            </FadeContainerBlack>
            {homeData &&
              <IntroParagraph>{homeData.statement} </IntroParagraph>
            }
          </VideoSection>
          <FeaturedEvents data={homeData} imgParallax={imgAParallaxOffset} backImgParallax={imgBParallaxOffset}></FeaturedEvents>
          <FloatingMenu color={BEIGE}/>
          <BackToAnimation to="#" onClick={startAnimationAgain} onTouchEnd={startAnimationAgain}>
            <img src={isologo} alt="take flight logo to go back to animation"/>
          </BackToAnimation>
          <Footer/>
        </Container>
      </Wrapper>
  );
};
