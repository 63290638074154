import styled from "styled-components";
import { BEIGE } from "../../constants/colors";
import { MD, LG } from "../../constants/mediaQueries";

export const Debugger = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  min-height: 100vh;
  white-space: pre-wrap;

`;

export const TestButton = styled.button`
  ${props => props.hide ? 'display: none;' : ''}
  margin-top: 1rem;
  position: absolute;
  left: 35vw;
  width: 30vw;
  text-transform: uppercase;
  background-color: black;
  color: ${BEIGE};
  border: none;
  outline: none;
  font-weight: bolder;
  font-size: 1rem;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${(props) => props.loaded ? 'display: none;' : ''}
  height: 5px;
  margin: auto;
`;
export const LoadingBar = styled.div`
  height: 5px;
  background: #f4f2f0;
  width: ${(props) => props.loading + 20}%;
  transition: width 500ms;
`;

export const IntroWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-color: #000;


  canvas {
    height: 95vh !important;
    width: 100vw !important;
    object-fit: contain;
    object-position: center;
  }

  @media (max-width: ${MD}) {
    height: 200vh;
    position: relative;
    canvas {
      position: sticky;
      top: 0;
      object-fit: contain;
      object-position: center;
    }
  }
`;

export const EnterButton = styled.button`
  width: 20vw;
  left: 40vw;
  height: 20vh;
  top: 40vh;
  background: transparent;
  padding: 10px 20px;
  position: absolute;
  border:none;
  cursor: pointer;
  color: transparent;
  @media (max-width: ${LG}) {
    width: 50vw;
    left: 25vw;
  }
  @media (max-width: ${MD}) {
    display: none;
  }

  &:hover {
    color: transparent;
  }
  &:active {
    color: transparent;
  }
`;

export const ScrollDownButton = styled.button`
  width: 20vw;
  left: 40vw;
  height: 20vh;
  top: 40vh;
  background: transparent;
  padding: 10px 20px;
  position: absolute;
  border:none;
  cursor: pointer;
  color: transparent;

  display: none;
  @media (max-width: ${MD}) {
    display: block;
  }

  &:hover {
    color: transparent;
  }
  &:active {
    color: transparent;
  }
`;

