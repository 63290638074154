import { Link } from "gatsby";
import styled from "styled-components";
import { BEIGE } from "../../constants/colors";

import { SM, MD, XL, LG } from "../../constants/mediaQueries";

export const EventGrid = styled.div`
  width: 100%;
  min-height: 250vh;
  max-width: ${XL};
  padding: 3rem 1rem;
  margin: 4rem auto;
  display: grid;
  grid-template-rows: 7fr 2fr 4fr 2fr 4fr 1fr 2fr 6fr 1fr 5fr 5fr 3fr 6fr;
  grid-template-columns: 3fr 2fr 3fr 2fr 3fr 2fr 2fr 2fr 2fr 4fr 2fr; 


  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 1rem;
    margin: 0 auto;
  }
`;

export const EventImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  will-change: transform;

  ${props => `
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
`}

  @media (max-width: ${SM}) {
    width: 100%;
    height: 60vh;
  } 

`;

export const EventTitle = styled.h3`
  font-weight: bold;
  font-size: 50px;

  @media (max-width: ${MD}) {
    font-size: 40px;
  }
  @media (max-width: ${SM}) {
    font-size: 2.75rem;
    line-height: 1.2;
  }
`;

export const EventTitleMobile = styled(Link)`
  font-weight: bold;
  font-size: 50px;
  text-decoration: none;

  color: ${BEIGE};
  &:hover {
    color: ${BEIGE};
  }
  &:active {
    color: ${BEIGE};
  }

  @media (max-width: ${MD}) {
    font-size: 40px;
  }
  @media (max-width: ${SM}) {
    font-size: 2.75rem;
    line-height: 1.2;
  }
`

export const EventLocation = styled.h5`
  font-weight: bold;
  font-size: 20px;
  z-index: 100;
  will-change: transform;

  ${props => props.mobileOnly ? 'display:none;' : ''}

  @media (max-width: ${SM}) {
    margin-top: 1rem;
    margin-bottom: 7rem;
    font-size: 18px;
    ${props => props.mobileOnly ? 'display:block;': 'display:none;'}
  }

  p {
    margin-bottom: 0;
  }
`;

export const EventTitleWrapper = styled.div`
display: none;
z-index: 100;
will-change: transform;

@media (max-width: ${SM}) {
  display: block;
  position: relative;
  width: 100%;
  height: 3rem;
  mix-blend-mode: difference;
}
`;
export const EventTitleContainer= styled.div`
@media (max-width: ${SM}) {
  position: absolute;
  top: 0;
  height: 6rem;
  max-width: 90%;
  display: flex;
  align-items: center;
}
`;

export const EventDetails = styled(Link)`
  text-align: left;
  color: ${BEIGE};
  text-decoration: none;
  cursor: pointer;
  z-index: 100;
  will-change: transform;


  &:hover {
    color: ${BEIGE};
  }
  &:active {
    color: ${BEIGE};
  }

  ${props => `
    grid-column: ${props.column};
    grid-row: ${props.row};
  
  `}

  @media (max-width: ${SM}) {
    display: none;
  }
}
`;

export const MarqueesContainer = styled.div`
  position: sticky;
  top: 0px; 
  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
  }
  z-index: 600;
`;

export const EventImageContainer = styled.div`
  ${props => `
    grid-column: ${props.column};
    grid-row: ${props.row};

    ${props.hideOnMobile ? `
    @media (max-width: ${SM}) {
      display: none;
    } 
    `: ''}
  `}
`

export const EventsSectionContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  color: ${BEIGE};
  font-weight: bold;

  @media (max-width: ${SM}) {
    margin-top: 7rem;
  }
`;
