import React from "react";
import { EventGrid, EventImage, EventTitleWrapper, EventTitleMobile, EventsSectionContainer, MarqueesContainer, EventLocation, EventTitle, EventTitleContainer, EventDetails, EventImageContainer } from "./style";
import {MarqueeHeader} from "../MarqueeHeader";
import {MarqueeLogo} from "../MarqueeLogo";

export const FeaturedEvents = ({data, imgParallax, backImgParallax}) => {
  return (
    <EventsSectionContainer>
      <MarqueesContainer>
         <MarqueeHeader sectionTitle={true} text="Events"/>
         <MarqueeLogo/>
      </MarqueesContainer>
      {data && 
      <EventGrid>
        {/* event 1 */}
        <EventTitleWrapper style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}>
          <EventTitleContainer>
            <EventTitleMobile to={`${data?.event1_url}`}>{data?.event1_title}</EventTitleMobile>
          </EventTitleContainer>
        </EventTitleWrapper>
        <EventDetails row="1 / span 1" column="2 / span 4" to={`${data?.event1_url}`} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}>
          <EventTitle>{data?.event1_title}</EventTitle>
          <EventLocation>{data?.event1_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>
        </EventDetails>

        <EventImageContainer column="6 / span 5" row="1 / span 2">
          <EventImage img={data?.event1_image1?.asset.url} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}/>
        </EventImageContainer>

        <EventLocation mobileOnly={true}
          style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}
          >{data?.event1_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>

        <EventImageContainer column="10 / span 2" row="2 / span 3" hideOnMobile={true}>
          <EventImage img={data?.event1_image2?.asset.url} style={{transform: `translate3d(0,${backImgParallax}vh,0)`}} />
        </EventImageContainer>

        {/* event 2 */}
        <EventTitleWrapper style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}>
          <EventTitleContainer>
            <EventTitleMobile to={`${data?.event2_url}`}>{data?.event2_title}</EventTitleMobile>
          </EventTitleContainer>
        </EventTitleWrapper>
        <EventDetails row="8 / span 1" column="7 / span 4" to={`${data?.event2_url}`} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}>
          <EventTitle>{data?.event2_title}</EventTitle>
          <EventLocation>{data?.event2_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>
        </EventDetails>

        <EventImageContainer column="5 / span 4" row="4 / span 3">
          <EventImage img={data?.event2_image1?.asset.url} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}/>
        </EventImageContainer>
        <EventLocation 
          mobileOnly={true}
          style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}
          >{data?.event2_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>


        <EventImageContainer column="1 / span 5" row="6 / span 3" hideOnMobile={true}>
          <EventImage img={data?.event2_image2?.asset.url}  style={{transform: `translate3d(0,${backImgParallax}vh,0)`}}/>
        </EventImageContainer>

        {/* event 3 */}
        <EventTitleWrapper style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}>
          <EventTitleContainer>
            <EventTitleMobile to={`${data?.event3_url}`}>{data?.event3_title}</EventTitleMobile>
          </EventTitleContainer>
        </EventTitleWrapper>
        <EventDetails row="11 / span 1" column="4 / span 3" to={`${data?.event3_url}`} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}>
          <EventTitle>{data?.event3_title}</EventTitle>
          <EventLocation>{data?.event3_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>
        </EventDetails>

        <EventImageContainer column="7 / span 4" row="10 / span 3">
          <EventImage img={data?.event3_image1?.asset.url} style={{transform: `translate3d(0,${imgParallax}vh,0)`}}/>
        </EventImageContainer>
        <EventLocation 
          mobileOnly={true}
          style={{transform: `translate3d(0,${imgParallax*0.8}vh,0)`}}
        >{data?.event3_city?.split('-').map((city) => <p key={city}>{city}</p>)}</EventLocation>

        <EventImageContainer column="3 / span 5" row="12 / span 2" hideOnMobile={true}>
          <EventImage img={data?.event3_image2?.asset.url}  style={{transform: `translate3d(0,${backImgParallax}vh,0)`}}/>
        </EventImageContainer>
      </EventGrid>
      }
      
    </EventsSectionContainer>
  );
};
