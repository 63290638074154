import React, { useEffect, useState, useRef } from "react";
import { IntroWrapper,ScrollDownButton, LoadingBar, LoadingContainer, EnterButton, Debugger } from "./style";
import meshTexture0 from "./matcaps/matcapimg2.jpg";
import meshTexture1 from "./matcaps/matcap0.jpeg";
import meshTexture2 from "./matcaps/matcapimg.jpg";
import meshTexture3 from "./matcaps/matcap8.jpg";
import meshTexture4 from "./matcaps/matcap6.jpeg";
import meshTexture5 from "./matcaps/matcap7.jpeg";
import background from "./matcaps/bckgrnd.jpg"

import sanityClient from "../../client.js";
import * as THREE from "three";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';


const map = function (x,a,b,c,d){
  var y = (x-a)/(b-a)*(d-c)+c;
  return y;
}

export const Intro3d = (props) => {
  const ref = useRef();
  const mouseRef = React.useRef();
  const isMobileRef = React.useRef();
  const cameraOffsetRef = React.useRef();
  const isIntro = React.useRef();
  // const buttonRef = useRef(null);
  const textureRef = React.useRef();
  const stopRef = React.useRef();
  const start = Date.now();
  let animationFrameId;

  const [debug, setDebug] = useState('');
  const [initDebug, setInitDebug] = useState('');


  const [mouse, setMouse] = useState({ center: 0, dirY: 0, dirX: 0});
  const [stop, setStop] = useState(false);
  const [loading, setLoading] = useState(0);
  const [ongoingAnimation, setOngoingAnimation] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [cameraZOffset, setCameraZOffset] = useState(7);
  // const [textureIndex, setTextureIndex] = useState(0);
  const [sceneDat, setSceneDat] = useState( {
    matcap: null,
    matcaps:[],
    children: null,
    canvasRef: ref.current
  });


  // useEffect(() => {
  //   textureRef.current = textureIndex;
  // }, [textureIndex])

  useEffect(() => {
    mouseRef.current = mouse;
  }, [mouse])

  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile])

  useEffect(() => {
    cameraOffsetRef.current = cameraZOffset;
  }, [cameraZOffset])

  useEffect(() => {
    if (isIntro.current && !props.intro) {
      // Comment next line for ever-present animation
      setStop(true);
    }
    if (!isIntro.current && props.intro) {
      setStop(false);
    }
    isIntro.current = props.intro;

  }, [props.intro])

  useEffect(() => {
    if (props.restart) {
      // Comment next line for ever-present animation
      setStop(false);
    }

  }, [props.restart])

  useEffect(() => {
    stopRef.current = stop;
  }, [stop])

  

  useEffect(() => {
    sanityClient
      .fetch(
        `
        *[_type == "background-image"]{
          title,
          background_image{
                alt,
                caption,
                asset->{
                  url
            }
          },
        }`
      )
      .then((data) => {
      
      })
      .catch(console.error); 


      document.addEventListener("mousemove", (event) => {
        // plan B for mouse tracking
        // const xB = Math.min(Math.abs(( event.clientX / window.innerWidth ) * 2 - 1)*1.3, 1);
        const x = Math.min(Math.max(map(Math.abs(( event.clientX / window.innerWidth ) * 2 - 1) * 2, 0.25, 2, 0, 1.5 ), 0), 1);
        const y = Math.min(Math.max(map(Math.abs(( event.clientY / window.innerHeight ) * 2 - 1) * 2, 0.25, 2, 0, 1.5 ), 0), 1);
        
        setMouse({ 
          center: Math.min(Math.min((x + y), 1) * 1.5, 1), 
          dirY: (( event.clientY / window.innerHeight ) - 0.5) * 2,
          dirX: (( event.clientX / window.innerWidth ) - 0.5) * 2
        });
      });


      window.addEventListener("scroll", (event) => {
        // const winScroll =
        //   document.body.scrollTop || document.documentElement.scrollTop
        // const height =
        //   document.documentElement.scrollHeight -
        //   document.documentElement.clientHeight

        let scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
       
        if (window.location.pathname === '/') sessionStorage.setItem("home-scroll", JSON.stringify(scrollY))

        if (scrollY/window.innerHeight >= 1 && !stopRef.current) {
          enterpage();
        }

      // Uncomment next block for ever-present animation
      // if (scrollY/window.innerHeight <= 0.75 && stopRef.current) {
      //   enterpage();
      // }

      });


      
    
      const loadEverything = function () {
        let debugText = 'loading... ';
        setDebug(debugText);
        const sceneData = {
          matcap: null,
          children: null,
          canvasRef: ref.current
        };
        
        const manager = new THREE.LoadingManager();
        
        manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
          setLoading(itemsLoaded/itemsTotal*100);

          debugText+= ' Started loading file: '+ url + '.\n Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.'
          setDebug(debugText);

          // console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        manager.onLoad = function ( ) {
          debugText +='\n Loading complete! All good over here';
          setDebug(debugText);

          // console.log( 'Loading complete! took', (Date.now() - startLoad)/1000, ' seconds. aka ', (Date.now() - startLoad)/1000/60, ' mins.');
          setLoading(100);
          setLoaded(true);
          setSceneDat(sceneData);
          if (stopRef.current) {
            initScene(sceneData);
          }
        };
        manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
          setLoading(itemsLoaded/itemsTotal*100);
          debugText +=  '\n Loading file: ' + url + '.\n Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.'
          setDebug(debugText);

          // console.log( 'Loading file: ' + url + '.\n Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        
        manager.onError = function ( url, e ) {
          debugText +=  '\n \n ERROR!! loading ' + url + '.\n ERROR: ' + e;
          setDebug(debugText);


          // console.log( 'There was an error loading ' + url);
        };
        
        const texLoader = new THREE.TextureLoader(manager);
          
        const loader = new GLTFLoader(manager);
        
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '/models/dracogltf/' );
        loader.setDRACOLoader( dracoLoader );
    
        texLoader.load(meshTexture0, function (text) {
          sceneData.matcap = text;
        });
    
        // texLoader.load(meshTexture1, function (text) {
        //   sceneData.matcaps.push(text);
        // });
    
        // texLoader.load(meshTexture2, function (text) {
        //   sceneData.matcaps.push(text);
        // });
    
        // texLoader.load(meshTexture3, function (text) {
        //   sceneData.matcaps.push(text);
        // });
    
        // texLoader.load(meshTexture4, function (text) {
        //   sceneData.matcaps.push(text);
        // });
    
        // texLoader.load(meshTexture5, function (text) {
        //   sceneData.matcaps.push(text);
        // });
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          loader.load('/models/letters-mobile-lower-res.gltf', function ( gltf ) {
            sceneData.children = gltf.scene.children;
          }, undefined, function ( error ) {
            console.error( error );
            debugText +='\n ERROR LOADING GLTF :' + error;
  
          } );
        } else {
          loader.load('/models/all-letters-alone-lower-res.gltf', function ( gltf ) {
            sceneData.children = gltf.scene.children;
          }, function ( xhr ) {
  
            debugText +='\n GLTF loader :' + ( xhr.loaded ) + ' loaded' ;  
        
          }, function ( error ) {
            console.error( error );
            debugText +='\n ERROR LOADING GLTF :' + error;
  
          } );
        }
       
    
      }

      handleResize();

      loadEverything();

      return () => {
        cancelAnimationFrame(animationFrameId);
        window.removeEventListener('scroll', () => {});
        window.removeEventListener('resize', () => {});
      }
      
  }, []);

  useEffect(() => {
    if(loaded && sceneDat.matcap && !stop && !ongoingAnimation) {

      initScene(sceneDat);
    }

  }, [sceneDat, loaded, stop])

  const initScene = function ({matcap,children, canvasRef,matcaps}) {
    let debuggy = '\nEntered init scene function..'
    setInitDebug(debuggy)
    if (!matcap || !children || !canvasRef) return;
    setOngoingAnimation(true);
    debuggy = '\n... and actually started init (canvasRef, children & matcap exist)'
    setInitDebug(debuggy)
    
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    camera.position.setZ(Math.max(map(window.innerWidth, 1300, 350, 2.5, 7), 3.5) + window.innerHeight/window.innerWidth);
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef,
      antialias: true
    });
    renderer.setClearAlpha(0);
    renderer.setSize(window.innerWidth, window.innerHeight);


    const meshes = [];
    const pivots = [];
    const material = new THREE.MeshMatcapMaterial({matcap: matcap});


    // buttonRef.current.addEventListener("click", (event) => {
    //   const newIndex = (textureRef.current + 1) > 5  ? 0 : textureRef.current + 1;
    //   setTextureIndex(newIndex);
    //   material.matcap = matcaps[newIndex];
    // });


    // buttonRef.current.addEventListener("touchstart", (event) => {
    //   const newIndex = (textureRef.current + 1) > 5 ? 0 : textureRef.current + 1;
    //   setTextureIndex(newIndex);
    //   material.matcap = matcaps[newIndex];
    // }, false);

    const obj = {
      A002: { 
        offset: 1.5,
        orbitPos: 0,
      },
      F002: { 
        offset: 0,
        orbitPos: 0.25,
      },
      KE002: { 
        offset: 0.75,
        orbitPos: 0.5,
        letterRand: 0
      },
      GH002: { 
        offset: -1.5,
        orbitPos: 0.75,
        letterRand: 0
      },
      LI002: { 
        offset: -0.5,
        orbitPos: 1,
        letterRand: 0
      },
      T002: { 
        offset: 2,
        orbitPos: 1.25,
        letterRand: 0
      },
      T_1002: { 
        offset: -2,
        orbitPos: 1.5,
        letterRand: 0
      }
    }

    const pivotDistance = 0.75;


    children.forEach((child, i) => {
        const geo = child.geometry;
        geo.computeVertexNormals();
        const mesh = new THREE.Mesh( geo, material );
        mesh.name = child.name;
        mesh.scale.y *= 0.0003;
        mesh.scale.x *= 0.0003;
        mesh.scale.z *= 0.0003;
        mesh.rotateX(1.5);

        const randX = Math.random() - 0.5;
        const randY = Math.random() - 0.5;

        obj[mesh.name].letterRand = {
          x: randX > 0 ? (randX) + 0.2 : (randX) - 0.2, 
          y: randY > 0 ? randY + 0.2 : randY - 0.2, 
        };

        meshes.push(mesh);

        //for debug purposes only:
        //pivots[i] = new THREE.Mesh(new THREE.SphereGeometry(0.2), new THREE.MeshBasicMaterial({ color: new THREE.Color('#ffff00')}));
        pivots[i] = new THREE.Object3D();
        pivots[i].position.set(0,0,-1);
        pivots[i].add(mesh)
        scene.add(pivots[i]);

        //pivots[i].rotation.y = obj[mesh.name].letterRand.y;
        //pivots[i].rotation.x = obj[mesh.name].letterRand.x;
    })

    window.addEventListener("resize", (event) => {
      handleResize();
      if (!stopRef.current) renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    });
  
    let countdown = 70;

    const animate = function () {
      
      renderer.render( scene, camera );
      let scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const scrolled = Math.min(scrollY/window.innerHeight, 1);
       if (pivots && pivots.length && meshes.length) {
        if (stopRef.current) {
          pivots.forEach((pivot, i) => {          
           
            pivot.rotation.x += (0 - pivot.rotation.x) * countdown/480 ;
            pivot.rotation.y += (0 - pivot.rotation.y) * countdown/480 ;
            meshes[i].position.z += (0 - meshes[i].position.z) * countdown/480 ; 
            meshes[i].position.x += (0 - meshes[i].position.x) * countdown/480 ; 
            
          });
          countdown -= 1;
          if (countdown === 0) {
            setOngoingAnimation(false);

            cancelAnimationFrame(animationFrameId);
            return;
          }
        } else {
          countdown = 70;
          if (isMobileRef.current) {
            pivots.forEach((pivot, i) => {
              // Posicion de las Letras
              meshes[i].position.x += 
                // Alejandose del centro
                // cuando scrolled es 1 esto no anda. cuando scrolled es 1 esto anda
                0.02 * (obj[meshes[i].name].offset - meshes[i].position.x) * ((1 - scrolled)) +
                // Volviendo al centro 
                (meshes[i].position.x) * (- scrolled)  * 0.05;
              
              meshes[i].position.z += 
                // Alejandose del centro
                0.05  * (pivotDistance + obj[meshes[i].name].orbitPos - meshes[i].position.z) * (1 - scrolled) +
                // Volviendo al centro
                (meshes[i].position.z) * (-scrolled)  * 0.05;
    
              // Rotacion de los pivots
              pivot.rotation.x += 
                // Alejandose del centro
                ( 0.09 * obj[meshes[i].name].letterRand.x * (1 - scrolled) * (0.5) + 
                // Volviendo al centro
                (pivot.rotation.x % (2 * Math.PI)) * (-scrolled)  * 0.05);
              
              pivot.rotation.y += 
                // Alejandose del centro
                ( 0.09 * obj[meshes[i].name].letterRand.y * (1 - scrolled) * (0.7) + 
                // Volviendo al centro 
                (pivot.rotation.y % (2 * Math.PI)) * (-scrolled)  * 0.05);
    
              // Limpiar pivot rotation 
              if (Math.abs(pivot.rotation.y) > (2 * Math.PI)) {
                pivot.rotation.y = pivot.rotation.y % (2 * Math.PI);
              }
              if (Math.abs(pivot.rotation.x) > (2 * Math.PI)) {
                pivot.rotation.x = pivot.rotation.x % (2 * Math.PI);
              }
    
            });
          } else {
            pivots.forEach((pivot, i) => {
              // Posicion de las Letras
              meshes[i].position.x += 
                // Alejandose del centro
                // cuando center es 0 esto no anda. cuando center es 0.5 esto anda
                0.02 * (obj[meshes[i].name].offset - meshes[i].position.x) * Math.abs(mouseRef.current.center) +
                // Volviendo al centro 
                (meshes[i].position.x) * (Math.abs(mouseRef.current.center) - 1)  * 0.05;
              
              meshes[i].position.z += 
                // Alejandose del centro
                0.05  * (pivotDistance + obj[meshes[i].name].orbitPos - meshes[i].position.z) * Math.abs(mouseRef.current.center) +
                // Volviendo al centro
                (meshes[i].position.z) * (Math.abs(mouseRef.current.center) - 1)  * 0.05;
    
              // Rotacion de los pivots
              pivot.rotation.x += 
                // Alejandose del centro
                ( 0.12 * obj[meshes[i].name].letterRand.x * Math.abs(mouseRef.current.center) * (mouseRef.current.dirY) + 
                // Volviendo al centro
                (pivot.rotation.x % (2 * Math.PI)) * (Math.abs(mouseRef.current.center) - 1)  * 0.05);
              
              pivot.rotation.y += 
                // Alejandose del centro
                ( 0.12 * obj[meshes[i].name].letterRand.y * Math.abs(mouseRef.current.center) * (mouseRef.current.dirX) + 
                // Volviendo al centro 
                (pivot.rotation.y % (2 * Math.PI)) * (Math.abs(mouseRef.current.center) - 1)  * 0.05);
    
              // Limpiar pivot rotation 
              if (Math.abs(pivot.rotation.y) > (2 * Math.PI)) {
                pivot.rotation.y = pivot.rotation.y % (2 * Math.PI);
              }
              if (Math.abs(pivot.rotation.x) > (2 * Math.PI)) {
                pivot.rotation.x = pivot.rotation.x % (2 * Math.PI);
              }
    
            });
          }
          camera.position.setZ(0.15 * Math.sin(( Date.now() - start )*0.00251) + cameraOffsetRef.current);
        }
      }

      animationFrameId = requestAnimationFrame( animate );
    };

    animate();
  }

  const enterpage = function () {
    if (!stopRef.current) {
      setStop(true);
      props.onEnterClick();
    } else {
      setStop(false);
    }
  }

  const scrollDown = function () {
    window.scrollTo({top: window.innerHeight*1, behavior: 'smooth'});
  }
 
  const handleResize = function () {
    // innerWidth goes from 350 to 1300
    // camera offset goes from 7 to 4.
    // but keep in mind aspect radio 
    setCameraZOffset(Math.max(map(window.innerWidth, 1300, 350, 3, 7), 3.5) + window.innerHeight/window.innerWidth);
    
    setIsMobile(window.innerWidth <= 768);
  }

  return (
    <IntroWrapper showBackgroundImage={textureRef.current === 0 || textureRef.current === 1} backgroundImage={background} loaded={loaded}>
      {/* <Debugger>
        {`is mobile? ${/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}\n`}
        {debug}
        {initDebug}
      </Debugger> */}
        <LoadingContainer loaded={loaded}>
          <LoadingBar loading={loading}/>
        </LoadingContainer>
        <canvas ref={ref} />
        {isIntro.current &&
          <EnterButton onClick={enterpage}>ENTER</EnterButton>
        }
        {isIntro.current &&
          <ScrollDownButton onClick={scrollDown} onTouchEnd={scrollDown}></ScrollDownButton>
        }
    </IntroWrapper>
  );
};
