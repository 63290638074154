import styled from "styled-components";
import { BLACK } from "../../constants/colors";
import { SM, MD } from "../../constants/mediaQueries";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  z-index: 500;
  opacity: 80%;
  height: 120px;

  @media (max-width: ${MD}) {
    height: 80px;
  }
  @media (max-width: ${SM}) {
    height: 50px;
  }
  

  img {
    height: 120px;

    @media (max-width: ${MD}) {
      height: 80px;
    }
    @media (max-width: ${SM}) {
      height: 50px;
    }
  
  }

  @media (max-width: ${SM}) {
    &::after {
      box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      -webkit-box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      -moz-box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      content: ' ';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;   
    }
  }
`;

export const Track = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-logo 17s linear infinite;
  @media (max-width: ${MD}) {
    animation: marquee-logo 10s linear infinite;

  }

  @media (max-width: ${SM}) {
    animation: verticallogo 6s linear infinite;
  }
  
  img {
    vertical-align: top;
  }

  @keyframes marquee-logo {
    from {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      transform: translateX(-100%);
     
    }
    to {
      -webkit-transform: translateX(100vw);
      -moz-transform: translateX(100vw);
      -moz-transform: translateX(100vw);
      transform: translateX(100vw);
    }
  }

  @keyframes verticallogo {
    from {
      -webkit-transform: translateY(-150px);
      -moz-transform: translateY(-150px);
      -moz-transform: translateY(-150px);
      transform: translateY(-150px);
    }
    to {
      -webkit-transform: translateY(100vh);
      -moz-transform: translateY(100vh);
      -moz-transform: translateY(100vh);
      transform: translateY(100vh);
    }
  }

  @media (max-width: ${SM}) {
    .content img {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg);
  
      -webkit-transform-origin:left bottom;
      -moz-transform-origin:left bottom;
      -moz-transform-origin:left bottom;
      transform-origin:left bottom;

      vertical-align: center;

      width: 150px;
      height: auto;
    }
  }

  
`;

export const MarqueeLogoWrapper = styled.div`
  color: #f2e7d7;
  overflow: hidden;
  position: absolute;
  top: calc(38vh + 7vw);

  @media (max-width: ${SM}) {
    font-size: 3rem;
    top: 0;
    right: 1.5rem;
    width: 2.75rem;
    height: 100vh;
    writing-mode: vertical-lr;
  }

`;
