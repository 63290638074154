import styled from "styled-components";
import { BEIGE, BLACK } from "../../constants/colors";
import { SM, MD } from "../../constants/mediaQueries";

export const MarqueeHeaderWrapper = styled.div`
  font-size: 54px;
  font-weight: bold;
  color: ${BEIGE};
  margin: 0;
  overflow: hidden;
  
  ${props => props.sectionTitle ? `
    position: absolute;
    top: 38vh;
    font-size: 7.5rem;
    z-index: 500;
  `: ''}

  @media (max-width: 930px) {
    font-size: 6rem;
  }

  @media (max-width: ${MD}) {
    font-size: 5rem;
  }
  @media (max-width: ${SM}) {
    font-size: 3rem;
    top: 0;
    right: 1vw;
    width: 3rem;
    height: 100vh;
    writing-mode: vertical-lr;
  }

  @media (max-width: 350px) {
    font-size: 2rem;
    width: 2rem;

  }

  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 7.5rem;
    @media (max-width: 640px) {
      height: 5rem;
    }
  }

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 7.5s linear infinite;


    @media (max-width: ${MD}) {
      animation: marquee 6s linear infinite;

    }
    @media (max-width: ${SM}) {
      animation: verticalmarquee 6s linear infinite;
    }
  }

  @keyframes marquee {
    from {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100vw);
      -moz-transform: translateX(-100vw);
      -moz-transform: translateX(-100vw);
      transform: translateX(-100vw);
    }
  }


  @keyframes verticalmarquee {
    from {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-100vh);
      -moz-transform: translateY(-100vh);
      -moz-transform: translateY(-100vh);
      transform: translateY(-100vh);
    }
  }

  
  @media (max-width: ${SM}) {
    .marquee::after {
      box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      -webkit-box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      -moz-box-shadow: 0px 100px 10px -86px ${BLACK} inset;
      content: ' ';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;   
    }
  }

  .marquee .track .content {
    display: flex;
    justify-content: space-evenly;
    line-height: 1;
    @media (max-width: ${SM}) {
      height: 200vh;
    }

    span {
      display: block;
      width: 50vw;

      @media (max-width: ${SM}) {
        width: auto;
        height: 50vh;
      }
    }
  }
`;
