import styled from "styled-components";
import {BLACK, BEIGE} from "../../constants/colors"
import { Link } from "gatsby";
import { SM, MD, LG, XL } from "../../constants/mediaQueries";


export const Wrapper = styled.div`
  background: ${BLACK};
`;

export const BackToAnimation = styled(Link)`
  position: fixed;
  top: 30px;
  left: 30px;
  display: block;
  z-index: 500;
  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
  }
  img {
    width: 60px;
  }
`;


export const FadeContainerBlack = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  ::after {
    box-shadow: 0px 202px 82px -86px ${BLACK} inset;
    -webkit-box-shadow: 0px 202px 82px -86px ${BLACK} inset;
    -moz-box-shadow: 0px 202px 82px -86px ${BLACK} inset;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;   
  }

  @media (max-width: ${SM}) {
    ::after {
      box-shadow: 0px 150px 52px -86px ${BLACK} inset;
      -webkit-box-shadow: 0px 150px 52px -86px ${BLACK} inset;
      -moz-box-shadow: 0px 150px 2px -86px ${BLACK} inset;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;   
    }
  }
`

export const FadeContainerBeige = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 3px 0;

  ::after {
    box-shadow: 0px -232px 82px -86px ${BLACK} inset;
    -webkit-box-shadow: 0px -232px 82px -86px ${BLACK} inset;
    -moz-box-shadow: 0px -232px 82px -86px ${BLACK} inset;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;    
  }

  @media (max-width: ${SM}) {
    ::after {
      box-shadow: 0px -152px 52px -86px ${BLACK} inset;
      -webkit-box-shadow: 0px -152px 52px -86px ${BLACK} inset;
      -moz-box-shadow: 0px -152px 52px -86px ${BLACK} inset;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;    
    }
  
  }

  img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    display: none;


    @media (min-width: ${LG}) {
      display: none;
    }
  }

  

`

export const VideoWrapper = styled.div`

height: 100%;
position: relative;
display: flex;
align-items: center;

position: relative;
/* padding-bottom: 56.25%; 
height: 0; */

width: 1000px;

@media (max-width: ${SM}) {
  width: calc(100%*4/3);
}

@media (min-width: ${MD}) {
  width: 2000px;
}

@media (min-width: ${XL}) {
  width: calc(100%*4/3);
}

iframe, object, embed {
  min-width: calc(100vh*4/3);
  min-height: calc(100vw*3/4);
  object-fit: fill;
  object-position: top center;
  height: 1000px;
  width: 1000px;

  @media (min-width: ${MD}) {
    height: 2000px;
    width: 2000px;
  }

  @media (max-width: ${SM}) {
    width: 100vw;
  } 

  @media (min-width: ${XL}) {
    width: 100vw;
  }
  
}

`;

export const VideoSection = styled.div`
      
    background-color: ${BLACK};
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    isolation: isolate;
`;




export const IntroParagraph = styled.p`
  text-transform: uppercase;
  font-size: 7.5rem;
  text-align: justify;
  line-height: 1.2;
  z-index: 100;
  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
    font-size: 6.5rem;
  }
  @media (max-width: ${MD}) {
    font-size: 5rem;
  }
  @media (max-width: ${SM}) {
    font-size: 2.5rem;
  }
`;

export const LinkText = styled.p`
  .hovered {
    color: #4814cc;
  }

  color: #000;
  font-style: bold;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 0;

  @media (max-width: ${MD}) {
    font-size: 18px;
  }
  @media (max-width: ${SM}) {
    font-size: 14px;
  }
`;

export const Container = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${BEIGE};
  overflow-x: clip;

  text-transform: uppercase;

  background-color: ${BLACK};

  ${props => props.show ? 'display: block;' : 'display: none;'}
`;

export const Star = styled.img`
  height: 100px;
  position: absolute;
  z-index: 50;
  top: ${props => props.top}vh;
  left: ${props => props.left}vw;
`;

export const StarContainer = styled.div`
  position: relative;
`;
